<template>
  <div class="about">
    <h1>This is an about page</h1>
    <!-- <v-btn to="/about/child">Child</v-btn> -->
    <router-link to="/about/child1" custom v-slot="{ navigate, isActive }">
      <v-btn :color="isActive ? 'primary' : ''" @click="navigate" role="link">
        Child1
      </v-btn>
    </router-link>

    <router-link to="/about/child2" custom v-slot="{ navigate, isActive }">
      <v-btn :color="isActive ? 'primary' : ''" @click="navigate" role="link">
        Child2
      </v-btn>
    </router-link>

    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "About",
  metaInfo: {
    title: "介紹",
  },
  data: () => ({
    //
  }),
};
</script>
<style scoped lang="scss">
.v-btn {
  background-color: aquamarine;
}
</style>
